export default {
  top: [
    {
      name: 'Dashboard', url: '/app', icon: 'Home', ionicon: 'icofont-ui-home', exact: true,
    },
    {
      name: 'Fitness Centers', url: '/app/fitness-centers', icon: 'Home', ionicon: 'icofont-building', exact: true,
    },
  ],
  admin: [
    {
      name: 'Dashboard', url: '/app', icon: 'Home', ionicon: 'icofont-ui-home', exact: true,
    },
	{
      name: 'Customers', url: '/app/customers', icon: 'Home', ionicon: 'icofont-users-alt-1', exact: true,
    },
	{
      name: 'Machines', url: '/app/machines', icon: 'Home', ionicon: 'icofont-volcano', exact: false,
    },
	{
      name: 'M-Allocation', url: '/app/machine-allocation', icon: 'Home', ionicon: 'icofont-binary', exact: true,
    },
	{
      name: 'Reports', url: '/app/machine-daily-report', icon: 'Home', ionicon: 'icofont-attachment', exact: true,
    },
	{
      name: 'Excel Report', url: '/app/machine-excep-report', icon: 'Home', ionicon: 'icofont-attachment', exact: true,
    },
	{
      name: 'App Users', url: '/app/app-users', icon: 'Home', ionicon: 'icofont-users', exact: true,
    },
	{
      name: 'Settings', url: '/app/settings', icon: 'Home', ionicon: 'icofont-ui-settings', exact: true,
    },
   
  ],
  bottom: [
  ],
};
