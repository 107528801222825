import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, InputGroup, Dropdown, DropdownButton
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import Add from './Add';
import Edit from './Edit';
import ViewData from './ViewData';
import ViewPrintData from './ViewPrintData';
import EditData from './EditData';

const { Option } = Select;

class MachineReport extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddCustomer: false,
			showEditModal: false,
			showViewModal: false,
			showViewPrintDataModal: false,
			paginationPageSize: 30,
			editData: [],
			dataList: [],
			dataView: [],
			dataFilter:[{filter_date:'',filter_text: '',}],
		}		
		
		this.loadData = this.loadData.bind(this);
		this.handleAddCustomerClose = this.handleAddCustomerClose.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleCustomerEditClick = this.handleCustomerEditClick.bind(this);
		this.handleEditModalClose = this.handleEditModalClose.bind(this);
		this.handleViewModalClose = this.handleViewModalClose.bind(this);
		this.handleDateSearchClick = this.handleDateSearchClick.bind(this);
		this.handleViewPrintDataModalClick = this.handleViewPrintDataModalClick.bind(this);
		this.handleViewPrintDataModalClose = this.handleViewPrintDataModalClose.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	handleAddCustomerClick(record){
		this.setState({
			editData: record,
			showAddCustomer: true,
		});
	}
	
	handleAddCustomerClose(){
		this.setState({
			editData: [],
			showAddCustomer: false,
		});
	}
	
	handleCustomerEditClick(record){
		this.setState({
			editData: record,
			showEditModal: true,
		});
	}
	
	handleEditModalClose(){
		this.setState({
			editData: [],
			showEditModal: false,
		});
	}
	
	handleViewModalClick(record){
		this.setState({
			editData: record,
			showViewModal: true,
		});
	}
	
	
	handleViewModalClose(){
		this.setState({
			editData: [],
			showViewModal: false,
		});
	}
	
	handleViewPrintDataModalClick(record){
		this.setState({
			editData: record,
			showViewPrintDataModal: true,
		});
	}
	
	
	handleViewPrintDataModalClose(){
		this.setState({
			editData: [],
			showViewPrintDataModal: false,
		});
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({
			paginationPageSize: pageSize
		});
	}
	
	loadData(){
		try{
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			
			axios.get(Api.Url+'v1/admin/machines/daily_data?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.machine_code).indexOf(v)>-1) || 
				(upperCase(obj.customer_name).indexOf(v)>-1) ||
				(upperCase(obj.customer_mobile).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemoveCustomer(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to remove the machine ${record.machine_code}`,
			buttons: {
				Yes: function(){
					$this.confirmRemoveCustomer(record);
				},
				No: function(){ }
			}
		});
	}
	
	confirmRemoveCustomer(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.customer_id);
			axios.post(Api.Url+'v1/admin/machine/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					var dat = d.filter(function(obj){
						return(obj.machine_id != record.machine_id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleDateSearchClick(){
		try{
			
			var d = $("#txtReportDate").val();
			if(d.length<4){
				alert('Please select a date');
				return;
			}
			
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			
			axios.get(Api.Url+'v1/admin/machines/daily_data?report_date='+d+'&api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		const columns = [
			 {
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record, index)=>(
					<center>
						{index+1}
					</center>
				)
			}, 
			{
				title: 'Reading Time',
				dataIndex: 'reading_on',
				key: 'reading_on',
				render: (text, record)=>(
					<>
					{record.reading_on} <br /> {record.reading_time}
					</>
				)
			},
			{
				title: 'Customer',
				dataIndex: 'customer_name',
				key: 'customer_name',
				render: (text, record)=>(
					<>
					{record.customer_name} <br /> {record.customer_mobile}
					</>
				)
			},
			{
				title: 'Machine Code',
				dataIndex: 'machine_code',
				key: 'machine_code',
				render: (text, record)=>(
					<>
					{record.machine_code} <br /> {record.machie_site_location}
					</>
				)
			},
			{
				title: 'Capacity',
				dataIndex: 'machine_capacity',
				key: 'machine_capacity',
			},
			{
				title: 'MF Value',
				dataIndex: 'machine_mf_value',
				key: 'machine_mf_value',
			},
			{
				title: 'Cumulative',
				dataIndex: 'cumulative',
				key: 'cumulative',
			},
			{ title: 'op_hrs', dataIndex: 'op_hrs', key: 'op_hrs', },
			{ title: 'stop_hours', dataIndex: 'stop_hours', key: 'stop_hours', },
			{ title: 'g1_gen', dataIndex: 'g1_gen', key: 'g1_gen', },
			{ title: 'g1_hrs', dataIndex: 'g1_hrs', key: 'g1_hrs', },
			{ title: 'g2_gen', dataIndex: 'g2_gen', key: 'g2_gen', },
			{ title: 'g2_hrs', dataIndex: 'g2_hrs', key: 'g2_hrs', },
			{ title: 'imp_kwh', dataIndex: 'imp_kwh', key: 'imp_kwh', },
			{ title: 'exp_kwh', dataIndex: 'exp_kwh', key: 'exp_kwh', },
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
					{/*<Tooltip title="View Print Data" >
							<Button type="button" size="xs" variant="success" onClick={this.handleCustomerEditClick.bind(this, record)} >
								<i className="icofont-eye" />
							</Button>
						</Tooltip>	
						&nbsp;
						<Tooltip title="Click to Edit" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleCustomerEditClick.bind(this, record)} >
								<i className="icofont-edit" />
							</Button>
						</Tooltip>	
						&nbsp;
						<Tooltip title="View Machine Data" >
							<Button type="button" size="xs" variant="info" onClick={this.handleViewModalClick.bind(this, record)} >
								<i className="icofont-eye" />
							</Button>
					</Tooltip >*/}
					<DropdownButton drop="left" title="Manage" size="sm" variant="light"  >
						<Dropdown.Item onClick={this.handleViewPrintDataModalClick.bind(this, record)} >View Print Data</Dropdown.Item>
						<Dropdown.Item onClick={this.handleViewModalClick.bind(this, record)} >View Machine Data</Dropdown.Item>
						<Dropdown.Item onClick={this.handleAddCustomerClick.bind(this, record)}  >Edit Machine Data</Dropdown.Item>
					</DropdownButton>
					</center>
				)
			},
		];
		
		return(
			<React.Fragment>
				
				<Card style={{position:'sticky',top:'56px',zIndex:'1'}}>
					<Card.Body>
						<Row>
							<Col md={3} >
								<InputGroup size="sm" >
									<Form.Control
										id="txtReportDate"
										siz="sm"
										type="date"
										max={momentDate((new Date()), 'YYYY-MM-DD')}
									/>
									<InputGroup.Prepend>
									  <Button onClick={this.handleDateSearchClick} >
										<i className="icofont-search" ></i>
									  </Button>
									</InputGroup.Prepend>
								  </InputGroup>
							 
							 </Col>
							<Col md={6} >
								<span className="input-icon">
									<i className="material-icons" >search</i>
									<Form.Control  
										size="sm" 
										placeholder="Search by Machine Code, Customer Name, Customer Mobile" 
										onChange={this.handleSearchOnChange} />
								  </span>
							 </Col>							 
							 <Col md={3} >
								<div className="text-right" >
									<ButtonGroup >
										<Button size="sm" variant="outline-secondary" onClick={this.loadData} >
											<i className="icofont-refresh" /> Refresh
										</Button>
										{/*<Button size="sm" variant="outline-secondary" onClick={this.handleAddCustomerClick} >
											<i className="icofont-plus" /> Add Machine
										</Button>*/}
									</ButtonGroup>
								</div>
							 </Col>
						</Row>
					</Card.Body>
				</Card>
				
				<div className="mt-50 ps-long-data-table" >
					<Table 
						size="small"
						columns={columns} 
						bordered
						title={() => `Total ${Object.keys(this.state.dataView).length} record in list`} 
						footer={() => `Total ${Object.keys(this.state.dataView).length} record in list`} 
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						pagination={{ 
							pageSize: this.state.paginationPageSize,
							onShowSizeChange: this.handlePaginationSizeChange,
							position: ['bottomRight'],
						}} />
				</div>
				
				<Modal size="lg" show={this.state.showAddCustomer} onHide={this.handleAddCustomerClose} backdrop="static" >
					<Modal.Header closeButton >
						Edit Machine Data
					</Modal.Header>
					<Modal.Body>
						<EditData data={this.state.editData} afterFinish={this.handleDateSearchClick} />
					</Modal.Body>
				</Modal>
				
				<Modal size="lg" show={this.state.showViewModal} onHide={this.handleViewModalClose} backdrop="static" >
					<Modal.Header closeButton >
						View Machine Data
					</Modal.Header>
					<Modal.Body>
						<ViewData data={this.state.editData} afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
				<Modal size="lg" show={this.state.showViewPrintDataModal} onHide={this.handleViewPrintDataModalClose} backdrop="static" >
					<Modal.Header closeButton >
						View Machine Print Data
					</Modal.Header>
					<Modal.Body>
						<ViewPrintData data={this.state.editData} afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default MachineReport;
