import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, InputGroup, Dropdown, DropdownButton
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import Menu from './Menu';
const { Option } = Select;

const styles = {
	innerTableHeaderTd: {
		fontSize: '12px',
		border: '1px solid black',
		textAlign: 'center',
		borderCollapse: 'collapse',
		padding: '2px',
		margin:'0px',
		fontWeight:'bold',
	},
}

class MachineDailyInput extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
			yesterdayData: [],
			customers: [],
			selectedCustomer: '',
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCustomerChange = this.handleCustomerChange.bind(this);
		
	}
	
	componentDidMount(){
		
		this.loadCustomers();
	}
	
	loadCustomers(){
		try{
			this.setState({
				showLoader: true,
				customers: [],
			});
			
			axios.get(Api.Url+'v1/admin/customers?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						customers: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(this.state.selectedCustomer==''){
				message.error('Select a customer');
				return;
			}
			
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			
			axios.post(Api.Url+'v1/admin/machine/list/customer_wise_inputdata', $("#frm_MAchine_Mod").serialize()).then(res=>{
				
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						yesterdayData: res['data'].data1,
						showLoader: false,
					});
					console.log(this.state);
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	handleCustomerChange(value){
		this.setState({
			selectedCustomer: value
		});
	}
	
	exportTableToExcel(tableID, filename = ''){
		try{
			
			this.setState({
				showLoader: true
			});
				
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
			
			// Specify file name
			filename = filename?filename+'.xls':'excel_data.xls';
			
			// Create download link element
			downloadLink = document.createElement("a");
			
			document.body.appendChild(downloadLink);
			
			if(navigator.msSaveOrOpenBlob){
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob( blob, filename);
				
				this.setState({ showLoader: false });
			}else{
				// Create a link to the file
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
			
				// Setting the file name
				downloadLink.download = filename;
				
				//triggering the function
				downloadLink.click();
				
				this.setState({ showLoader: false });
			}	
			
		}
		catch(error){
			this.setState({ showLoader: false });
		}
	}
	
	loadData(items){
		var tr = [];
		{items.map((item,i)=>{
												
			tr.push( <tr key={i} >
				<td style={styles.innerTableHeaderTd}  >{i+1}</td>
				<td style={styles.innerTableHeaderTd} >{item.machine_code}</td>
				<td style={styles.innerTableHeaderTd} >{item.cumulative}</td>
				<td style={styles.innerTableHeaderTd} >{item.op_hrs}</td>
				<td style={styles.innerTableHeaderTd} >{item.stop_hours}</td>
				<td style={styles.innerTableHeaderTd} >{item.g1_gen}</td>
				<td style={styles.innerTableHeaderTd} >{item.g1_hrs}</td>
				<td style={styles.innerTableHeaderTd} >{item.g2_gen}</td>
				<td style={styles.innerTableHeaderTd} >{item.g2_hrs}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kwh}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kwh}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kuvarh_lag}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kvarh_lead}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kvarh}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kuarh_lag}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kvarh_lead}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kvarh}</td>
			</tr>);
		})
		}
		return tr;
	}
	
	
	render(){
		
		let sno=1;
			
		return(
			<React.Fragment>
			
			<Menu />

				<Spin spinning={this.state.showLoader} >
				<Card>
					<Card.Body>
						<form
							action=""
							method="post"
							encType="multipart/form-data"
							id="frm_MAchine_Mod"
							onSubmit={this.handleFormSubmit} 
						>
						
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__customer" value={this.state.selectedCustomer} />
							
							<Row>
								<Col md={6} >
										<Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a customer"
										  optionFilterProp="children"
										  onChange={this.handleCustomerChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) =>
											  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											value={this.state.selectedCustomer}	
										>
										{this.state.customers.map((item,i)=>{
											return <Option value={item.customer_id} >{item.customer_name}</Option>;
										})
										}
									</Select>
								 </Col>
								 
								 <Col md={3} >
										<Form.Control
											id="i__reportDate"
											name="i__reportDate"
											siz="sm"
											type="date"
											max={momentDate((new Date()), 'YYYY-MM-DD')}
											required
										/>																		 
								 </Col>
															 
								 <Col md={3} >
									<div className="text-left" >
										<ButtonGroup >
											<Button type="submit" size="sm" variant="secondary" >
												Load Data
											</Button>
											{/*<Button size="sm" variant="outline-secondary" onClick={this.handleAddCustomerClick} >
												<i className="icofont-plus" /> Add Machine
											</Button>*/}
										</ButtonGroup>
									</div>
								 </Col>
							</Row>
							</form>
					</Card.Body>
				</Card>
				
					<Card>
						<Card.Body>
							<Row className="mb-20" >
								<Col md={12}>
									<div className="text-right" >
										<Button
											type="button"
											size="sm"
											onClick={this.exportTableToExcel.bind(this,"tbl_export","test")} 
										>	
											Export Excel
										</Button>
									</div>
								</Col>
							</Row>
								
							
							<div style={{overflowX: 'scroll' }} >
							
							<table  align="center"  style={{borderCollapse:'collapse'}} id="tbl_export" >
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="2"  style={styles.innerTableHeaderTd} >
										<h4>{upperCase(getls('loginCompany', 'master_company_name'))}</h4>
									</td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} > 
									<td align="center" style={styles.innerTableHeaderTd} >Reading Taken On  &emsp;
										{Object.keys(this.state.dataView).length > 0 ? momentDate(this.state.dataView[0].reading_on, 'DD-MMM-YYYY') : null}
									</td>
									<td align="center" style={styles.innerTableHeaderTd} >Reading Taken On  &emsp;
										{Object.keys(this.state.yesterdayData).length > 0 ? momentDate(this.state.yesterdayData[0].reading_on, 'DD-MMM-YYYY') : null}
									</td>
								</tr>
								<tr>
									<td colSpan="" style={{verticalAlign: 'top'}} >
										<table  align="center"  style={{borderCollapse:'collapse'}} >
											<tr  style={{borderCollapse:'collapse'}} >
												<td  style={styles.innerTableHeaderTd}>S.No</td>
												<td  style={styles.innerTableHeaderTd}>Code</td>
												<td  style={styles.innerTableHeaderTd}>Cummulative</td>
												<td  style={styles.innerTableHeaderTd}>Op.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>Stop.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>G1.Gen</td>
												<td  style={styles.innerTableHeaderTd}>G1.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>G2.Gen</td>
												<td  style={styles.innerTableHeaderTd}>G2.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>IMPKWH</td>
												<td  style={styles.innerTableHeaderTd}>EXPKWH</td>
												<td  style={styles.innerTableHeaderTd}>IMP Kvarh Lag</td>
												<td  style={styles.innerTableHeaderTd}>EXP Kvarh Lead</td>
												<td  style={styles.innerTableHeaderTd}>IMP Kvarh</td>
												<td  style={styles.innerTableHeaderTd}>EXP Kvarh Lag</td>
												<td  style={styles.innerTableHeaderTd}>IMP Kvarh Lead</td>
												<td  style={styles.innerTableHeaderTd}>EXP Kvarh</td>
											</tr>
											{this.loadData(this.state.dataView)}
										</table>
									</td>
									<td colSpan="" style={{verticalAlign: 'top'}} >
										<table  align="center"  style={{borderCollapse:'collapse'}} >
											<tr  style={{borderCollapse:'collapse'}} >
												<td  style={styles.innerTableHeaderTd}>S.No</td>
												<td  style={styles.innerTableHeaderTd}>Code</td>
												<td  style={styles.innerTableHeaderTd}>Cummulative</td>
												<td  style={styles.innerTableHeaderTd}>Op.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>Stop.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>G1.Gen</td>
												<td  style={styles.innerTableHeaderTd}>G1.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>G2.Gen</td>
												<td  style={styles.innerTableHeaderTd}>G2.Hrs</td>
												<td  style={styles.innerTableHeaderTd}>IMPKWH</td>
												<td  style={styles.innerTableHeaderTd}>EXPKWH</td>
												<td  style={styles.innerTableHeaderTd}>IMP Kvarh Lag</td>
												<td  style={styles.innerTableHeaderTd}>EXP Kvarh Lead</td>
												<td  style={styles.innerTableHeaderTd}>IMP Kvarh</td>
												<td  style={styles.innerTableHeaderTd}>EXP Kvarh Lag</td>
												<td  style={styles.innerTableHeaderTd}>IMP Kvarh Lead</td>
												<td  style={styles.innerTableHeaderTd}>EXP Kvarh</td>
											</tr>
											{this.loadData(this.state.yesterdayData)}
										</table>
									</td>
								</tr>
								
							</table>
							
							</div>
						
						</Card.Body>
					</Card>
					
				</Spin>
				
				
				
			</React.Fragment>
		);
	}
	
}
export default MachineDailyInput;
