import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class BasicSettings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save Changes',
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/settings/basic', $("#frmMasterSettings").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
					this.context.updateCompany(res['data'].data);
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
						
		return(
			<React.Fragment>				
				<Row>
					<Col md={12} >
						
						<Spin spinning={this.state.showLoader} >
						
						<Card>
							<Card.Body>
						
								<form 
									action=""
									method="post"
									encType="multipart/form-data"
									id="frmMasterSettings"
									onSubmit={this.handleFormSubmit} 
								>
									<input type="hidden" name="api" value={this.context.state.api} />
									<Row>
										<Col md={9} >
											<label className="font-14 font-weight-600" > 
												Company Name 
												<span className="text-danger">  </span>
											</label>
										
											<Form.Control
												type="text"
												name="i__companyName"
												size="md"
												autoComplete={false}
												defaultValue={getls('loginCompany', 'master_company_name')}
												required
											/>
										</Col>
									</Row>
									
									<Row className="mt-35" >
										<Col md={9} >
											<label className="font-14" > 
												Company GST
												<span className="text-danger">  </span>
											</label>
										
											<Form.Control
												type="text"
												name="i__companyGst"
												size="sm"
												autoComplete={false}
												defaultValue={getls('loginCompany', 'master_company_gst')}
												
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={9} >
											<label className="font-14" > 
												<i>Company Address </i>
												<span className="text-danger"> * </span>
											</label>
										
											<Form.Control
												as="textarea"
												name="i__companyAddress"
												size="sm"
												rows="5"
												autoComplete={false}
												defaultValue={getls('loginCompany', 'master_company_address')}
												required
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={9} >
											<label className="font-14" > 
												<i>Mobile Number</i>
												<span className="text-danger"> * </span>
											</label>
										
											<Form.Control
												type="number"
												name="i__companyMobile"
												size="sm"
												autoComplete="off"
												defaultValue={getls('loginCompany', 'master_company_mobile')}
												required
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={9} >
											<label className="font-14" > 
												Phone Number
												<span className="text-danger">  </span>
											</label>
										
											<Form.Control
												type="text"
												name="i__companyPhone"
												size="sm"
												autoComplete="off"
												defaultValue={getls('loginCompany', 'master_company_phone')}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={9} >
											<label className="font-14" > 
												<i>Email Id</i>
												<span className="text-danger"> * </span>
											</label>
										
											<Form.Control
												type="email"
												name="i__companyEmail"
												size="sm"
												autoComplete={false}
												defaultValue={getls('loginCompany', 'master_company_email')}
												required
											/>
										</Col>
									</Row>
									
									<Row className="mt-35" >
										<Col md={12} >
											<div className="text-right" >
												<Button
													type="submit"
													size="sm"
													variant="outline-dark"
												>
													{this.state.btnText}
												</Button>
											</div>
										</Col>
									</Row>
								
								</form>
								
							</Card.Body>
						</Card>
						
						</Spin>
						
					</Col>
				</Row>
				
			</React.Fragment>
		);
	}
	
}
export default BasicSettings;
