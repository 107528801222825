import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, Table, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, integerIndMobile,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class ViewPrintData extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Update Machine',
			customers: [],
			selectedCustomer: [],
		}		
		
	}
	
	componentDidMount(){
		
		this.loadPrintData();
	}
	
	loadPrintData(){
		try{
			this.setState({
				showLoader: true,
				customers: [],
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('machine_id', this.props.data.machine_id);
			form.append('report_date', this.props.data.reading_on);
			
			axios.post(Api.Url+'v1/admin/machines/daily_print_data', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						customers: res['data'].data,
						selectedCustomer: this.getData('customer_id'),
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
		
	getData(field){
		try{
			var d = this.state.customers;
			return d[0][field];
		}
		catch(error){ }
	}
	
	render(){
		
						
		return(
			<React.Fragment>		
			<Spin spinning={this.state.showLoader} >
			<Table>
				<tbody>
					<tr>
						<td colSpan="2" align="center" >{upperCase(getls('loginCompany', 'master_company_name'))}</td>
					</tr>
					<tr>
						<td style={{fontSize: '14px'}} >
							Customer : {this.getData('customer_name')}<br />
							Email : {this.getData('customer_email')}<br />
							Reading On : {this.getData('reading_on')} {this.getData('reading_time')}
						</td>
						<td style={{fontSize: '14px'}} >
							Machine Code : {this.getData('machine_code')}<br />
							Location: {this.getData('machie_site_location')}<br />
							MF Value : {this.getData('machine_mf_value')}
						</td>
					</tr>
					<tr>
						<td colSpan="">
							<table width="100%" >
								<tr> <td>g1_gen</td> <td align="right" >{this.getData('g1_gen')}</td> </tr>
								<tr> <td>g1_hrs</td> <td align="right" >{this.getData('g1_hrs')}</td> </tr>
								<tr> <td>g2_gen</td> <td align="right" >{this.getData('g2_gen')}</td> </tr>
								<tr> <td>g2_hrs</td> <td align="right" >{this.getData('g2_hrs')}</td> </tr>
								<tr> <td>daily_gen</td> <td align="right" >{this.getData('daily_gen')}</td> </tr>
								<tr> <td>daily_hrs</td> <td align="right" >{this.getData('daily_hrs')}</td> </tr>
								<tr> <td>opr_hrs</td> <td align="right" >{this.getData('opr_hrs')}</td> </tr>
								<tr> <td>gen_hrs</td> <td align="right" >{this.getData('gen_hrs')}</td> </tr>
								
							</table>
						</td>
						<td colSpan="">
							<table width="100%" >
								<tr> <td>fr_hrs</td> <td align="right" >{this.getData('fr_hrs')}</td> </tr>
								<tr> <td>mt_hrs</td> <td align="right" >{this.getData('mt_hrs')}</td> </tr>
								<tr> <td>bd_hrs</td> <td align="right" >{this.getData('bd_hrs')}</td> </tr>
								<tr> <td>gd_hrs</td> <td align="right" >{this.getData('gd_hrs')}</td> </tr>
								<tr> <td>ab_hrs</td> <td align="right" >{this.getData('ab_hrs')}</td> </tr>
								<tr> <td>imp_kwh</td> <td align="right" >{this.getData('imp_kwh')}</td> </tr>
								<tr> <td>exp_kwh</td> <td align="right" >{this.getData('exp_kwh')}</td> </tr>
								<tr> <td>imp_kvarh</td> <td align="right" >{this.getData('imp_kvarh')}</td> </tr>
								<tr> <td colSpan="2"></td> </tr>
							</table>
						</td>
					</tr>
					<tr> <td>percentage_kvarh</td> <td align="right" >{this.getData('percentage_kvarh')}</td> </tr>								
					<tr> <td colSpan="2">
						Notes : {this.getData('notes')}
					</td> </tr>
					<tr> <td colSpan="2"></td> </tr>
				</tbody>
			</Table>
			</Spin>
			</React.Fragment>
		);
	}
	
}
export default ViewPrintData;
