import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, Table,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, integerIndMobile,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class MachineAllocation extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			user: [],
			machines: [],
			machinesView: [],
			allocated: [],
			users: [],
			selectedUser: '',
		}		
		
		this.handleUserChange = this.handleUserChange.bind(this);
		this.handleSearchFormSubmit = this.handleSearchFormSubmit.bind(this);
		this.handleResetClick = this.handleResetClick.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleDeselectAllClick = this.handleDeselectAllClick.bind(this);
		this.handleAllocateFormSubmit = this.handleAllocateFormSubmit.bind(this);
	}
	
	componentDidMount(){
		
		this.loadUsers();
	}
	
	loadUsers(){
		try{
			this.setState({
				showLoader: true,
				users: [],
			});
			
			axios.get(Api.Url+'v1/admin/users?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						users: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearchFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({
				showLoader: true,
				user: [],
				machines: [],
				machinesView: [],
				allocated: [],
			});
			
			axios.post(Api.Url+'v1/admin/user/allocated_machines', $("#frmSearchUser").serialize()).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						user: res['data'].user,
						machines: res['data'].machines,
						machinesView: res['data'].machines,
						allocated: res['data'].allocated,
						showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleResetClick(){
		this.setState({
			user: [],
			machines: [],
			allocated: [],
		});
	}
	
	handleUserChange(val){
		this.setState({selectedUser: val});
	}
	
	getUser(field){
		try{
			return this.state.user[field];
		}
		catch(error){ }
	}
	
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.machines.filter(function(obj){
				return( (upperCase(obj.machine_code).indexOf(v)>-1) || 
				(upperCase(obj.customer_name).indexOf(v)>-1));
			});
			
			this.setState({machinesView: d});
		}
		catch(error){
			
		}
	}
	
	handleColClick(record){
		try{
			var m = this.state.machines;
			var a = this.state.allocated;
			var index = a.findIndex(function(obj){
				return(obj.machine_id==record.machine_id);
			});
			
			if(index>-1){
				a.splice(index,1);
			}
			else{
				var s = m.findIndex(function(obj){
					return(obj.machine_id==record.machine_id);
				});
				var t = {machine_id: m[s].machine_id};
				//a.push(m[s]);
				a.push(t);
			}
			
			this.setState({allocated: a});
		}
		catch(error){ }
	}
	
	checkMachineAllocated(machine_id){
		try{
			var a = this.state.allocated;
			var index = a.findIndex(function(obj){
				return(obj.machine_id==machine_id);
			});
			return (index>-1);
		}
		catch(error){
			return false;
		}
	}
	
	handleDeselectAllClick(){
		this.setState({allocated: []});
	}
	
	handleAllocateFormSubmit(e){
		e.preventDefault();
		try{
			
			if(!window.confirm('Do you want to save changes?')){
				return;
			}
			
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/user/machines/allocate', $("#frmSaveUser_Allocate").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({
						machines: [],
						allocated: [],
						user: [],
						showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		var sno=1;
		
		return(
			<React.Fragment>
				<Spin spinning={this.state.showLoader} >
					{Object.keys(this.state.machines).length>0 ? 
					<>
						<Row>
							<Col md={3} >
								<label className="font-13"> User Name</label>
								<Form.Control
									type="text"
									size="sm"
									value={this.getUser('user_name')}
								/>
							</Col>
							<Col md={3} >
								<label className="font-13"> Mobile Number </label>
								<Form.Control
									type="text"
									size="sm"
									value={this.getUser('user_mobile')}
								/>
							</Col>
							<Col md={3} >
								<label className="font-13"> Designation </label>
								<Form.Control
									type="text"
									size="sm"
									value={this.getUser('user_designation')}
								/>
							</Col>
							<Col md={2} >
								<Button
									type="button"
									size="sm"
									variant="danger"
									className="mt-30"
									onClick={this.handleResetClick} 
								>
									<i className="icofont-close-line"></i> Reset 
								</Button>
							</Col>
						</Row>
												
						<Card className="mt-20" >
							<Card.Body>
								<Row className="mb-15" >
									<Col md={6} >
										<span className="input-icon">
											<i className="material-icons" >search</i>
											<Form.Control  
												size="sm" 
												placeholder="Search by Machine Code, Customer Name" 
												onChange={this.handleSearchOnChange} />
										  </span>
									</Col>
									<Col md={6} >
										<div className="text-right">
											{Object.keys(this.state.allocated).length} machines allocated
										</div>
									</Col>	
								</Row>
								<Table bordered size="sm" >
									<thead>
										<tr>
											<th>S.No</th>
											<th>Machine Code</th>
											<th>Customer Name</th>
											<th>Machine Location</th>
											<th>Make</th>
											<th>Select
												<Tooltip title="Click to Deselect all">
													<Button
														type="button"
														size="xs"
														variant="danger"
														style={{float: 'right'}}
														onClick={this.handleDeselectAllClick}
													>
														<i className="icofont-close-line"></i>
													</Button>
												</Tooltip>
											</th>
										</tr>
									</thead>
									<tbody>
										{this.state.machinesView.map((item,i)=>{
											return <tr key={i} >
												<td>{sno++}</td>
												<td>{item.machine_code}</td>
												<td>{item.customer_name}</td>
												<td>{item.machie_site_location}</td>
												<td>{item.machine_make}</td>
												<td align="center" onClick={this.handleColClick.bind(this, item)} >
													<Form.Check 
														type="checkbox" 
														checked={this.checkMachineAllocated(item.machine_id)}
													/>
												</td>
											</tr>;
										})
										}
									</tbody>
								</Table>
								<form
									action=""
									method="post"
									encType="multipart/form-data" 
									id="frmSaveUser_Allocate"
									onSubmit={this.handleAllocateFormSubmit} 
								>
									<input type="hidden" name="api" value={this.context.state.api} />
									<input type="hidden" name="i__userId" value={this.getUser('user_id')} />
									<input type="hidden" name="i__allocated" value={JSON.stringify(this.state.allocated)} />
									
									<Row>
										<Col md={6} >
											{Object.keys(this.state.allocated).length} machines allocated
										</Col>
										<Col md={6} >
											<div className="text-right" >
												<Button
													type="submit"
													variant="success"
													size="sm"
												>
													<i className="icofont-check-alt"></i> Save Changes
												</Button>
											</div>
										</Col>
									</Row>
									
								</form>
							</Card.Body>
						</Card>
					
					</>
					:
					<form 
						action=""
						method="post"
						encType="multipart/form-data" 
						id="frmSearchUser"
						onSubmit={this.handleSearchFormSubmit}
					>
						<input type="hidden" name="api" value={this.context.state.api} />
						<input type="hidden" name="i__mobile" value={this.state.selectedUser} />
						<Row>
							<Col md={2} >
								<label className="font-14">User Mobile Number</label>
							</Col>
							<Col md={3} >
								{/*<Form.Control
									type="number"
									size="sm"
									name="i__mobile"
									onKeyPress={integerIndMobile} 
									autoComplete={"off"}
									required
								/>*/}
								<Select
									  showSearch
									  style={{ width: '100%' }}
									  placeholder="Select a user"
									  optionFilterProp="children"
									  onChange={this.handleUserChange}
									  style={{width: '100%'}}
									  filterOption={(input, option) =>
										  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
									  }
									>
									{this.state.users.map((item,i)=>{
										return <Option value={item.user_mobile} >{item.user_name +' - '+ item.user_mobile}</Option>;
									})
									}
									
								</Select>
							</Col>
							<Col md={1} >
								<Button
									type="submit"
									size="sm"
									variant="theme"
									block
								>
									<i className="icofont-search-2"></i> View
								</Button>
							</Col>
							<Col md={12} >
								<hr />
							</Col>
						</Row>
					</form>
					}
				</Spin>
			</React.Fragment>
		);
	}
	
}
export default MachineAllocation;
