import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import Add from './Add';
import Edit from './Edit';
import TabMenu from './TabMenu';

const { Option } = Select;

class Machines extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showAddCustomer: false,
			showEditModal: false,
			paginationPageSize: 30,
			editData: [],
			dataList: [],
			dataView: [],
		}		
		
		this.loadData = this.loadData.bind(this);
		this.handleAddCustomerClick = this.handleAddCustomerClick.bind(this);
		this.handleAddCustomerClose = this.handleAddCustomerClose.bind(this);
		this.handlePaginationSizeChange = this.handlePaginationSizeChange.bind(this);
		this.handleSearchOnChange = this.handleSearchOnChange.bind(this);
		this.handleCustomerEditClick = this.handleCustomerEditClick.bind(this);
		this.handleEditModalClose = this.handleEditModalClose.bind(this);
	}
	
	componentDidMount(){
		this.loadData();
	}
	
	handleAddCustomerClick(){
		this.setState({
			showAddCustomer: true,
		});
	}
	
	handleAddCustomerClose(){
		this.setState({
			showAddCustomer: false,
		});
	}
	
	handleCustomerEditClick(record){
		this.setState({
			editData: record,
			showEditModal: true,
		});
	}
	
	handleEditModalClose(){
		this.setState({
			editData: [],
			showEditModal: false,
		});
	}
	
	handlePaginationSizeChange(current, pageSize) {
		this.setState({
			paginationPageSize: pageSize
		});
	}
	
	loadData(){
		try{
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			
			axios.get(Api.Url+'v1/admin/machines?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleSearchOnChange(e){
		try{
			var v = upperCase(e.target.value);
			var d = this.state.dataList.filter(function(obj){
				return( (upperCase(obj.machine_code).indexOf(v)>-1) || 
				(upperCase(obj.customer_name).indexOf(v)>-1) ||
				(upperCase(obj.customer_mobile).indexOf(v)>-1));
			});
			
			this.setState({dataView: d});
		}
		catch(error){
			
		}
	}
	
	handleRemoveCustomer(record){
		var $this = this;
		$.confirm({
			title: 'Confirm',
			content: `Do you want to remove the machine ${record.machine_code}`,
			buttons: {
				Yes: function(){
					$this.confirmRemoveCustomer(record);
				},
				No: function(){ }
			}
		});
	}
	
	confirmRemoveCustomer(record){
		try{
			this.setState({showLoader: true});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', record.machine_id);
			axios.post(Api.Url+'v1/admin/machine/remove', form).then(res=>{
				if(res['data'].status=='1'){
					var d = this.state.dataList;
					var dat = d.filter(function(obj){
						return(obj.machine_id != record.machine_id);
					});					
					this.setState({
						dataList: dat,
						dataView: dat,
						showLoader: false
					});
					message.success(res['data'].message || 'Success');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	render(){
		
		const columns = [
			 {
				title: '#',
				dataIndex: 'id',
				key: 'id',
				render: (text, record, index)=>(
					<>
						{index+1}
					</>
				)
			}, 
			{
				title: 'Machine Code',
				dataIndex: 'machine_code',
				key: 'machine_code'
			},
			{
				title: 'Customer Name',
				dataIndex: 'customer_name',
				key: 'customer_name'
			},
			{
				title: 'Customer Contact',
				dataIndex: 'customer_mobile',
				key: 'customer_mobile',
			},
			{
				title: 'MF Value',
				dataIndex: 'machine_mf_value',
				key: 'machine_mf_value',
			},			
			{
				title: 'Location',
				dataIndex: 'machie_site_location',
				key: 'machie_site_location',
			},
			{
				title: 'Capacity',
				dataIndex: 'machine_capacity',
				key: 'machine_capacity',
			},
			{
				title: 'Manage',
				dataIndex: 'id',
				key: 'id',
				render: (text,record)=> (
					<center>
						<Tooltip title="Click to Edit" >
							<Button type="button" size="xs" variant="warning" onClick={this.handleCustomerEditClick.bind(this, record)} >
								<i className="icofont-edit" />
							</Button>
						</Tooltip>
						&nbsp;
						<Tooltip title="Click to Delete" >
							<Button type="button" size="xs" variant="danger" onClick={this.handleRemoveCustomer.bind(this, record)} >
								<i className="icofont-trash" />
							</Button>
						</Tooltip >
					</center>
				)
			},
		];
		
		return(
			<React.Fragment>
				
				<TabMenu />
				
				<Card>
					<Card.Body>
						<Row>
							<Col md={9} >
								<span className="input-icon">
									<i className="material-icons" >search</i>
									<Form.Control  
										size="sm" 
										placeholder="Search by Machine Code, Customer Name, Customer Mobile" 
										onChange={this.handleSearchOnChange} />
								  </span>
							 </Col>
							 
							 <Col md={3} >
								<div className="text-right" >
									<ButtonGroup >
										<Button size="sm" variant="outline-secondary" onClick={this.loadData} >
											<i className="icofont-refresh" /> Refresh
										</Button>
										<Button size="sm" variant="outline-secondary" onClick={this.handleAddCustomerClick} >
											<i className="icofont-plus" /> Add Machine
										</Button>
									</ButtonGroup>
								</div>
							 </Col>
						</Row>
					</Card.Body>
				</Card>
				
				<div className="mt-50 ps-table" >
					<Table 
						size="small"
						columns={columns} 
						bordered
						title={() => `Total ${Object.keys(this.state.dataView).length} machines in list`} 
						footer={() => `Total ${Object.keys(this.state.dataView).length} machines in list`} 
						loading={this.state.showLoader}
						dataSource={this.state.dataView}
						pagination={{ 
							pageSize: this.state.paginationPageSize,
							onShowSizeChange: this.handlePaginationSizeChange,
							position: ['bottomRight'],
						}} />
				</div>
				
				<Modal size="100w" show={this.state.showAddCustomer} onHide={this.handleAddCustomerClose} backdrop="static" >
					<Modal.Header closeButton >
						Add Machine
					</Modal.Header>
					<Modal.Body>
						<Add afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
				<Modal size="100w" show={this.state.showEditModal} onHide={this.handleEditModalClose} backdrop="static" >
					<Modal.Header closeButton >
						Edit Machine
					</Modal.Header>
					<Modal.Body>
						<Edit data={this.state.editData} afterFinish={this.loadData} />
					</Modal.Body>
				</Modal>
				
			</React.Fragment>
		);
	}
	
}
export default Machines;
