import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, integerIndMobile,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class Add extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save Machine',
			customers: [],
			selectedCustomer: '',
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCustomerChange = this.handleCustomerChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadCustomers();
	}
	
	loadCustomers(){
		try{
			this.setState({
				showLoader: true,
				customers: [],
			});
			
			axios.get(Api.Url+'v1/admin/customers?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						customers: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/machine/save', $("#frmAddMachine_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddMachine_Mod").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						selectedCustomer: '',
						showLoader: false
					});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleCustomerChange(value){
		this.setState({
			selectedCustomer: value
		});
	}
	
	render(){
		
						
		return(
			<React.Fragment>		
						
					<Spin spinning={this.state.showLoader} >
									
						<form 
							action=""
							method="post"
							encType="multipart/form-data"
							id="frmAddMachine_Mod"
							onSubmit={this.handleFormSubmit} 
						>
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__customerId" value={this.state.selectedCustomer} />
							
							<Row>
								<Col md={6} >
									
									<Row>
										<Col md={3} >
											<label className="font-14" > 
												Machine Code
												<span className="text-danger"> * </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__machineCode"
												size="sm"
												autoComplete={false}
												style={{textTransform: 'uppercase'}}
												required
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Customer
												<span className="text-danger"> * </span>
											</label>
										</Col>
										<Col md={9} >
											<Select
												  showSearch
												  style={{ width: 180 }}
												  placeholder="Select a customer"
												  optionFilterProp="children"
												  onChange={this.handleCustomerChange}
												  style={{width: '100%'}}
												  filterOption={(input, option) =>
													  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
													}
													value={this.state.selectedCustomer}
												>
												{this.state.customers.map((item,i)=>{
													return <Option value={item.customer_id} > {item.customer_name} </Option>;
												})
												}
											</Select>											
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Machine MF Value
												<span className="text-danger"> * </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="number" step="any"
												name="i__mfValue"
												size="sm"
												autoComplete={false}
												required
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Activities
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__activities"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Site Location
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__siteLocation"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Location No
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__locationNo"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Machine Capacity
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__capacity"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Make
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__make"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												HTSC
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__htsc"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
								
									
								</Col>
								
								<Col md={6} >
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												SF No
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__sfNo"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Feeder
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__feeder"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												SS
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__ss"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												District
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__district"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Sale to Board
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__saleToBoard"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												Village
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="text"
												name="i__village"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
									<Row className="mt-15" >
										<Col md={3} >
											<label className="font-14" > 
												DLMS Meter Changed on
												<span className="text-danger">  </span>
											</label>
										</Col>
										<Col md={9} >
											<Form.Control
												type="date"
												name="i__dlmsChangedOn"
												size="sm"
												autoComplete={false}
											/>
										</Col>
									</Row>
									
								</Col>
								
							</Row>
							
							
							<Row className="mt-15" >
								<Col md={12} >
									<div className="text-right" >
										
										<Button
											type="reset"
											size="md"
											variant="danger"
										>
											<i className="icofont-close" ></i> Clear Form
										</Button>
										&emsp;
										<Button
											type="submit"
											size="md"
											variant="success"
										>
											<i className="icofont-check" ></i> {this.state.btnText}
										</Button>
									</div>
								</Col>
							</Row>
						
						</form>
						
					
					</Spin>					
				
			</React.Fragment>
		);
	}
	
}
export default Add;
