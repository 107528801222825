import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, InputGroup, Dropdown, DropdownButton
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import Menu from './Menu';

const { Option } = Select;

const styles = {
	innerTableHeaderTd: {
		fontSize: '12px',
		border: '1px solid black',
		textAlign: 'center',
		borderCollapse: 'collapse',
		padding: '2px',
		margin:'0px',
		fontWeight:'bold',
	},
}

class MachineExcelReport extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			showSendEmail: false,
			showMailLoader: false,
			dataList: [],
			dataView: [],
			customers: [],
			selectedCustomer: '',
			slectedCustomerInfo: [],
			toEmail: '',
			toSubject: '',
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCustomerChange = this.handleCustomerChange.bind(this);
		this.handleEmailModalOpen = this.handleEmailModalOpen.bind(this);
		this.handleEmailModalClose = this.handleEmailModalClose.bind(this);
		this.handleInputchange = this.handleInputchange.bind(this);
		this.handleEmailSendClick = this.handleEmailSendClick.bind(this);
		
	}
	
	componentDidMount(){
		
		this.loadCustomers();
	}
	
	loadCustomers(){
		try{
			this.setState({
				showLoader: true,
				customers: [],
			});
			
			axios.get(Api.Url+'v1/admin/customers?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						customers: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(this.state.selectedCustomer==''){
				message.error('Select a customer');
				return;
			}
			
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			
			axios.post(Api.Url+'v1/admin/machine/list/customer_wise_printdata', $("#frm_MAchine_Mod").serialize()).then(res=>{
				
				if(res['data'].status=='1'){
					this.setState({
						dataList: res['data'].data,
						dataView: res['data'].data,
						toEmail: res['data'].data[0]['customer_email'],
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	handleCustomerChange(value){
		this.setState({
			selectedCustomer: value
		});
	}
	
	exportTableToExcel(tableID, filename = ''){
		try{
			
			this.setState({
				showLoader: true
			});
				
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
			
			// Specify file name
			filename = filename?filename+'.xls':'excel_data.xls';
			
			// Create download link element
			downloadLink = document.createElement("a");
			
			document.body.appendChild(downloadLink);
			
			if(navigator.msSaveOrOpenBlob){
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob( blob, filename);
				
				this.setState({ showLoader: false });
			}else{
				// Create a link to the file
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
			
				// Setting the file name
				downloadLink.download = filename;
				
				//triggering the function
				downloadLink.click();
				
				this.setState({ showLoader: false });
			}	
			
		}
		catch(error){
			this.setState({ showLoader: false });
		}
	}
	
	handleEmailModalOpen(){
		this.setState({
			showSendEmail: true,
		});
	}
	
	handleEmailModalClose(){
		this.setState({
			showSendEmail: false,
			showMailLoader: false,
		});
	}
	
	handleEmailSendClick(){
		try{
			
			if(this.state.toEmail.length<5)
			{
				message.error('Enter Email Id');
				return;
			}
			if(this.state.toSubject.length<4)
			{
				message.error('Enter Mail Subject');
				return;
			}
			
			this.setState({ showMailLoader: true, });
			
			axios.post(Api.Url+'v1/admin/machine/email/customer_print_data', $("#frm_MAchine_Mod").serialize()).then(res=>{
				
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({
						toEmail: '',
						toSubject: '',
						showMailLoader: false,
						showSendEmail: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({ showMailLoader: false, });
		}
	}
	
	handleInputchange(e){
		this.setState({
			[e.target.name]: e.target.value
		});
	}
	
	getViewField(field){
		try{
			var d = this.state.dataView;
			return d[0][field];
		}
		catch(error){} 
	}
	
	render(){
		
		let sno=1;
			
		return(
			<React.Fragment>
			
			<Menu />

				<Spin spinning={this.state.showLoader} >
				<Card>
					<Card.Body>
						<form
							action=""
							method="post"
							encType="multipart/form-data"
							id="frm_MAchine_Mod"
							onSubmit={this.handleFormSubmit} 
						>
						
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__customer" value={this.state.selectedCustomer} />
							<input type="hidden" name="i__to" value={this.state.toEmail} />
							<input type="hidden" name="i__subject" value={this.state.toSubject} />
							
							<Row>
								<Col md={6} >
										<Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a customer"
										  optionFilterProp="children"
										  onChange={this.handleCustomerChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) =>
											  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											value={this.state.selectedCustomer}	
										>
										{this.state.customers.map((item,i)=>{
											return <Option value={item.customer_id} >{item.customer_name}</Option>;
										})
										}
									</Select>
								 </Col>
								 
								 <Col md={3} >
										<Form.Control
											id="i__reportDate"
											name="i__reportDate"
											siz="sm"
											type="date"
											max={momentDate((new Date()), 'YYYY-MM-DD')}
											required
										/>																		 
								 </Col>
															 
								 <Col md={3} >
									<div className="text-left" >
										<ButtonGroup >
											<Button type="submit" size="sm" variant="secondary" >
												Load Data
											</Button>
											{/*<Button size="sm" variant="outline-secondary" onClick={this.handleAddCustomerClick} >
												<i className="icofont-plus" /> Add Machine
											</Button>*/}
										</ButtonGroup>
									</div>
								 </Col>
							</Row>
							</form>
					</Card.Body>
				</Card>
				
				{Object.keys(this.state.dataView).length>0 ? 
					<Card>
						<Card.Body>
							<Row className="mb-20" >
								<Col md={12}>
									<div className="text-right" >
										<Button
											type="button"
											size="sm"
											variant="info"
											onClick={this.handleEmailModalOpen} 
										>	
											Send Email
										</Button>
										&emsp;
										<Button
											type="button"
											size="sm"
											onClick={this.exportTableToExcel.bind(this,"tbl_export","test")} 
										>	
											Export Excel
										</Button>
									</div>
								</Col>
							</Row>
																
							<table  align="center"  style={{borderCollapse:'collapse'}} id="tbl_export" >
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="20"  style={styles.innerTableHeaderTd} >
										<h4>{upperCase(getls('loginCompany', 'master_company_name'))}</h4>
									</td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="2" style={styles.innerTableHeaderTd}></td>
									<td style={styles.innerTableHeaderTd} >LCS</td>
									<td colSpan="7" style={styles.innerTableHeaderTd}></td>
									<td style={styles.innerTableHeaderTd} >MACHINE</td>
									<td colSpan="" style={styles.innerTableHeaderTd}></td>
									<td colSpan="3"  style={styles.innerTableHeaderTd} >TNEB GRID</td>
									<td colSpan=""  style={styles.innerTableHeaderTd} >TNEB METER</td>
									<td colSpan="4" style={styles.innerTableHeaderTd}></td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="2" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="3" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily<br />Gen</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily<br />Gen</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Opr<br />Hrs</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Gen<br />Hrs</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="2" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="4" style={styles.innerTableHeaderTd}></td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} >
									<td style={styles.innerTableHeaderTd} >S.No</td>
									<td style={styles.innerTableHeaderTd} >Machine</td>
									<td style={styles.innerTableHeaderTd} >G1<br />Gen</td>
									<td style={styles.innerTableHeaderTd} >G1<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >G2<br />Gen</td>
									<td style={styles.innerTableHeaderTd} >G2<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Daily<br />Gen</td>
									<td style={styles.innerTableHeaderTd} >Daily<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Opr<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Gen<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Fr<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Mt<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Bd<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Gd<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >AB<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >IMP<br />KWH</td>
									<td style={styles.innerTableHeaderTd} >EXP<br />KWH</td>
									<td style={styles.innerTableHeaderTd} >IMP<br />Kvarh</td>
									<td style={styles.innerTableHeaderTd} >% of Kvarh</td>
									<td style={styles.innerTableHeaderTd} >Notes</td>
								</tr>
								{this.state.dataView.map((item,i)=>{
									
									return <tr key={i} >
										<td style={styles.innerTableHeaderTd}  >{sno++}</td>
										<td style={styles.innerTableHeaderTd} >{item.machine_code}</td>
										<td style={styles.innerTableHeaderTd} >{item.g1_gen}</td>
										<td style={styles.innerTableHeaderTd} >{item.g1_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.g2_gen}</td>
										<td style={styles.innerTableHeaderTd} >{item.g2_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.daily_gen}</td>
										<td style={styles.innerTableHeaderTd}  >{item.daily_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.opr_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.gen_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.fr_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.mt_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.bd_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.gd_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.ab_hrs}</td>
										<td style={styles.innerTableHeaderTd} >{item.imp_kwh}</td>
										<td style={styles.innerTableHeaderTd} >{item.exp_kwh}</td>
										<td style={styles.innerTableHeaderTd} >{item.imp_kvarh}</td>
										<td style={styles.innerTableHeaderTd} >{item.percentage_kvarh}</td>
										<td style={styles.innerTableHeaderTd} >{item.notes}</td>
									</tr>;
								})
								}
							</table>
						
						</Card.Body>
					</Card>
				: null}
					
				</Spin>
				
				
				<Modal show={this.state.showSendEmail} onHide={this.handleEmailModalClose} >
					<Modal.Header closeButton >
						Send Email
					</Modal.Header>
					<Modal.Body>
						<Spin spinning={this.state.showMailLoader} >
							<Row>
								<Col md={12} >
									<label>To</label>
									<Form.Control
										type="text"
										id="txtEmail"
										name="toEmail"
										onChange={this.handleInputchange}
										value={this.state.toEmail}
									/>
								</Col>
								<Col md={12} className="mt-15" >
									<label>Subject</label>
									<Form.Control
										type="text"
										id="txtSubject"
										name="toSubject"
										onChange={this.handleInputchange}
										value={this.state.toSubject}
									/>
								</Col>
								<Col md={12} className="mt-15" >
									<div className="text-right" >
										<Button
											size="sm"
											variant="success"
											onClick={this.handleEmailSendClick}
										>
											Send Email
										</Button>
									</div>
								</Col>
							</Row>
						</Spin>
					</Modal.Body>
				</Modal>
				
				
			</React.Fragment>
		);
	}
	
}
export default MachineExcelReport;
