import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, Table, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, integerIndMobile, decimalKeyPress,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class EditData extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Update Machine',
			customers: [],
			selectedCustomer: [],
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCustomerChange = this.handleCustomerChange.bind(this);
	}
	
	componentDidMount(){
		

	}
	
	
	handleCustomerChange(value){
		this.setState({
			selectedCustomer: value
		});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/machine/update/daily_reading', $("#frm_Edit_popData").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getData(field){
		try{
			var d = this.props.data;
			return d[field];
		}
		catch(error){ }
	}
	
	render(){
		
						
		return(
			<Spin spinning={this.state.showLoader} >		
			<form action="" method="post" encType="multipart/form-data" id="frm_Edit_popData" onSubmit={this.handleFormSubmit} >
			
				<input type="hidden" name="api" value={this.context.state.api} />
				<input type="hidden" name="customer_id" value={this.getData('customer_id')} />
				<input type="hidden" name="machine_id" value={this.getData('machine_id')} />
				<input type="hidden" name="reading_on" value={this.getData('reading_on')} />
				
			<Table>
				<tbody>
					<tr>
						<td colSpan="2" align="center" >{upperCase(getls('loginCompany', 'master_company_name'))}</td>
					</tr>
					<tr>
						<td style={{fontSize: '14px'}} >
							Customer : {this.getData('customer_name')}<br />
							Email : {this.getData('customer_email')}<br />
							Reading On : {this.getData('reading_on')} {this.getData('reading_time')}
						</td>
						<td style={{fontSize: '14px'}} >
							Machine Code : {this.getData('machine_code')}<br />
							Location: {this.getData('machie_site_location')}<br />
							MF Value : {this.getData('machine_mf_value')}
						</td>
					</tr>
					<tr>
						<td colSpan="2">
							<table width="100%" >
								<tr> 
									<td>cumulative</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											step="any"
											name="cumulative" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('cumulative')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>op_hrs</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											step="any"
											name="op_hrs" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('op_hrs')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>stop_hours</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="stop_hours" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('stop_hours')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>g1_gen</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="g1_gen" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('g1_gen')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>g1_hrs</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="g1_hrs" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('g1_hrs')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>g2_gen</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="g2_gen" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('g2_gen')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>g2_hrs</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="g2_hrs" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('g2_hrs')} 
										/>
									</td> 
								</tr>
							</table>
						</td>	
					</tr>
					<tr>
						<td colSpan="">
							<table width="100%" >
								<tr> 
									<td>imp_kwh</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="imp_kwh" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('imp_kwh')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>c1</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="c1" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('c1')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>c2</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="c2" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('c2')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>c3</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="c3" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('c3')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>c4</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="c4" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('c4')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>c5</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="c5" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('c5')} 
										/>
									</td> 
								</tr>
							</table>
						</td>
						<td colSpan="">
							<table width="100%" >
								<tr> 
									<td>exp_kwh</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_kwh" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_kwh')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_c1</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_c1" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_c1')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_c2</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_c2" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_c2')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_c3</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_c3" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_c3')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_c4</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_c4" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_c4')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_c5</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_c5" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_c5')} 
										/>
									</td> 
								</tr>
							</table>
						</td>
					</tr>
					<tr>
						<td colSpan="2">
							<table width="100%" >
								<tr> 
									<td>imp_kuvarh_lag</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="imp_kuvarh_lag" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('imp_kuvarh_lag')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_kvarh_lead</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_kvarh_lead" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_kvarh_lead')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_kuarh_lag</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_kuarh_lag" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_kuarh_lag')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>imp_kvarh_lead</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="imp_kvarh_lead" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('imp_kvarh_lead')} 
										/>
									</td> 
								</tr>
								<tr> 
									<td>imp_kvarh</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="imp_kvarh" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('imp_kvarh')} 
											readOnly
										/>
									</td> 
								</tr>
								<tr> 
									<td>exp_kvarh</td> 
									<td align="right" width="250" >
										<Form.Control
											type="number" step="any" 
											name="exp_kvarh" 
											required 
											style={{textAlign:'right'}} 
											size="sm"
											onKeyPress={decimalKeyPress}
											defaultValue={this.getData('exp_kvarh')} 
											readOnly
										/>
									</td> 
								</tr>
							</table>
						</td>
					</tr>
					<tr> <td colSpan="2">
						Notes : 
						<Form.Control
							as="textarea"
							name="notes"
							size="sm"
							rows="4"
							defaultValue={this.getData('notes')}
						/>
					</td> </tr>
					<tr> <td colSpan="2" align="center" >
						<Button
							type="submit"
							variant="success"
						>
							<i className="icofont-check" ></i> Update Machine Data
						</Button>
					</td> </tr>
					<tr> <td colSpan="2"></td> </tr>
				</tbody>
			</Table>
			</form>
			</Spin>
		);
	}
	
}
export default EditData;
