import React, { Component } from 'react';
import {Redirect} from 'react-router-dom';
import $ from 'jquery';
import {getls, upperCase} from '../../../utils';
import {Tooltip} from 'antd';
import PsContext from '../../context/PsContext';

class Header extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			isRedirect: false,
		}		
		//this.handleWindowScroll = this.handleWindowScroll.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleLogOut = this.handleLogOut.bind(this);
		this.handleSidebarCollapse = this.handleSidebarCollapse.bind(this);
	}
	
	componentDidMount(){
		 //window.addEventListener('scroll', this.handleWindowScroll);
	}
	
	handleLogOut(){
		
		if(!window.confirm('Do you want to logout?')){
			return;
		}
		
		this.context.logout();
	}
	
	handleFormSubmit(e){
		e.preventDefault();
	}
	
	
	getPageTitle = () => {
		let name;
		this.props.routes.map(prop => {	

		  if (prop.path === this.props.location.pathname) {
			name = prop.name;
		  }
		  return null;
		});
		return name;
	};
	
	handleSidebarCollapse(){
		try{
			if($("body").hasClass("sidebar-collapse")){
				$("body").removeClass("sidebar-collapse");
				$("body").addClass("sidebar-expand");
			}
			else{
				$("body").removeClass("sidebar-expand");
				$("body").addClass("sidebar-collapse");
			}
		}
		catch(error){}
	}

	
	render(){
		
		if(this.context.state.userLogged != 'yes'){
			return(<Redirect to="/" />);
		} 
		
		return(
			<div className="main-header">
			  <a className="nav-link nav-link-faded rounded-circle nav-icon" href="javascript:;" data-toggle="sidebar" onClick={this.handleSidebarCollapse} >
				<i className="material-icons">menu</i>
				</a>
				{/*<form className="form-inline ml-3 d-none d-md-flex">
				<span className="input-icon">
				  <i className="material-icons">search</i>
				  <input type="text" placeholder="Search..." className="form-control bg-gray-200 border-gray-200 rounded-lg" />
				</span>
				</form>*/}
				<div>{this.getPageTitle()}</div>
			  <ul className="nav nav-circle ml-auto">
			  {/*<li className="nav-item d-md-none"><a className="nav-link nav-link-faded nav-icon" data-toggle="modal" href="#searchModal"><i className="material-icons">search</i></a></li>
				<li className="nav-item d-none d-sm-block"><a className="nav-link nav-link-faded nav-icon" href="#" id="refreshPage"><i className="material-icons">refresh</i></a></li>
				<li className="nav-item dropdown nav-notif">
				  <a className="nav-link nav-link-faded nav-icon has-badge dropdown-toggle no-caret" href="#" data-toggle="dropdown" data-display="static">
					<i className="icofont-bell-alt" ></i>
					<span className="badge badge-pill badge-danger">4</span>
				  </a>
				  <div className="dropdown-menu dropdown-menu-right p-0">
					<div className="card">
					  <div className="card-header bg-primary text-white">
						<i data-feather="bell" className="mr-2"></i>4 notifications
					  </div>
					  <div className="card-body p-0 pt-1">
						<div className="list-group list-group-sm list-group-flush">
						  <a href="javascript:void(0)" className="list-group-item list-group-item-action">
							<div className="media">
							  <span className="bg-primary text-white btn-icon rounded-circle"><i data-feather="user-plus"></i></span>
							  <div className="media-body ml-2">
								<p className="mb-0">5 New members joined today</p>
								<small className="text-secondary">
								  <i className="material-icons icon-inline mr-1">access_time</i>5 minutes ago
								</small>
							  </div>
							</div>
						  </a>
						  <a href="javascript:void(0)" className="list-group-item list-group-item-action">
							<div className="media">
							  <span className="bg-info text-white btn-icon rounded-circle"><i data-feather="message-square"></i></span>
							  <div className="media-body ml-2">
								<p className="mb-0">2 New comments</p>
								<small className="text-secondary">
								  <i className="material-icons icon-inline mr-1">access_time</i>10 minutes ago
								</small>
							  </div>
							</div>
						  </a>
						  <a href="javascript:void(0)" className="list-group-item list-group-item-action">
							<div className="media">
							  <span className="bg-success text-white btn-icon rounded-circle"><i data-feather="shopping-bag"></i></span>
							  <div className="media-body ml-2">
								<p className="mb-0">10+ New orders</p>
								<small className="text-secondary">
								  <i className="material-icons icon-inline mr-1">access_time</i>15 minutes ago
								</small>
							  </div>
							</div>
						  </a>
						  <a href="javascript:void(0)" className="list-group-item list-group-item-action">
							<div className="media">
							  <span className="bg-warning text-white btn-icon rounded-circle"><i data-feather="user-check"></i></span>
							  <div className="media-body ml-2">
								<p className="mb-0">Complete your account details</p>
								<small className="text-secondary">
								  <i className="material-icons icon-inline mr-1">access_time</i>20 minutes ago
								</small>
							  </div>
							</div>
						  </a>
						</div>
					  </div>
					  <div className="card-footer justify-content-center">
						<a href="javascript:void(0)">View more &rsaquo;</a>
					  </div>
					</div>
				  </div>
	</li>*/}
				<li>
					<span className="mr-10 btn text-white font-weight-600" >
					{upperCase(getls('loginCompany', 'master_company_name'))} 
					</span>
				</li>
				<li>
					<Tooltip title="Logout" >
					<a className="btn text-white" href="javascript:;" onClick={this.handleLogOut} tooltip="Logout" > <i className="icofont-logout" ></i> </a>
					</Tooltip>
				</li>
				{/*
				{/*<li className="nav-item dropdown ml-2">
				  <a className="nav-link nav-link-faded rounded nav-link-img dropdown-toggle px-2" href="javascript:;" >
					<img src="https://mimity-admin89.netlify.app/dist/img/user.svg" alt="Admin" className="rounded-circle mr-2" />
					<span className="d-none d-sm-block">Admin</span>
				  </a>
				  <div className="dropdown-menu dropdown-menu-right pt-0 overflow-hidden">
					<div className="media align-items-center bg-primary text-white px-4 py-3 mb-2">
					  <img src="https://mimity-admin89.netlify.app/dist/img/user.svg" alt="Admin" className="rounded-circle" width="50" height="50" />
					  <div className="media-body ml-2 text-nowrap">
						<h6 className="mb-0">Admin</h6>
						<span className="text-gray-400 font-size-sm">Administrator</span>
					  </div>
					</div>
					<a className="dropdown-item has-icon" href="javascript:void(0)"><i className="mr-2" data-feather="user"></i>Profile</a>
					<a className="dropdown-item has-icon" href="javascript:void(0)"><i className="mr-2" data-feather="settings"></i>Settings</a>
					<a className="dropdown-item has-icon text-danger" href="login2.html"><i className="mr-2" data-feather="log-out"></i>Sign out</a>
				  </div>
				</li>*/}
			  </ul>
			</div>
			);
	}
	
}
export default Header;
