import React, { Component } from 'react';
import $ from 'jquery';
import {NavLink} from 'react-router-dom';

class TabMenu extends Component{
	
	constructor(props){
		super(props);
		this.state = {
			
		}
	}

	
	render(){
		
	
		return(
			<ul className="nav nav-tabs">
			  <li className="nav-item">
				<NavLink className="nav-link" to="/app/machines" exact >Machines</NavLink>
			  </li>
			   <li className="nav-item">
				<NavLink className="nav-link" to="/app/machines/group" exact >Machine Group</NavLink>
			  </li>
			</ul>
		);
	}
	
}
export default TabMenu;
