import React from 'react';
import { Switch, Route, HashRouter } from 'react-router-dom';
import SiteLayout from '../layouts/SiteLayout';
import Login from '../views/pages/Login';

export default () => (

  <HashRouter>
    <Switch>
      <Route path="/app" component={SiteLayout} />

      <Route path="/" component={Login} />

    </Switch>
  </HashRouter>
);
