const COOKIE_KEY = 'nwp221';


// LOCAL
/*
const Api = {
  Url: 'http://localhost/nidhir/nwp/api/',
  ImageUrl: 'http://localhost/nidhir/nwp/api/public/uploads', // Dont put slash in last
  SiteUrl: 'http://localhost/nidhir/nwp',
  version: 'v1/',
};
*/

const Api = {
  Url: 'https://nwp.flywithapp.com/api/',
  ImageUrl: 'https://nwp.flywithapp.com/api/public/uploads', // Dont put slash in last
  SiteUrl: 'https://nwp.flywithapp.com',
  version: 'v1/',
};



const Var = {
  Title: 'NWP',
  Site: '',
  Phone: '6379705440',
  Email: '',
  Address: '',
  City: 'Tirunelveli',
  Pincode: ' 627002',
  RegularCourseFeeForScSt: 0,
  RegularCourseFeeForOthers: 50,
  SelfCourseFeeForScSt: 50,
  SelfCourseFeeForOthers: 50,
  EnableCashPayment: true,
  EnableOnlinePayment: true,
};

export {
  COOKIE_KEY,
  Api,
  Var,
};
