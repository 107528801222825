import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, InputGroup, Dropdown, DropdownButton
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, groupByMultiple,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import Menu from './Menu';
const { Option } = Select;

const styles = {
	innerTableHeaderTd: {
		fontSize: '12px',
		border: '1px solid black',
		textAlign: 'center',
		borderCollapse: 'collapse',
		padding: '2px',
		margin:'0px',
		fontWeight:'bold',
	},
}

class MachineMonthlyOutput extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			dataList: [],
			dataView: [],
			yesterdayData: [],
			customers: [],
			selectedCustomer: '',
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCustomerChange = this.handleCustomerChange.bind(this);
		this.handleServerExcelDownload = this.handleServerExcelDownload.bind(this);
		
	}
	
	componentDidMount(){
		
		this.loadCustomers();
	}
	
	loadCustomers(){
		try{
			this.setState({
				showLoader: true,
				customers: [],
			});
			
			axios.get(Api.Url+'v1/admin/customers?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						customers: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			
			if(this.state.selectedCustomer==''){
				message.error('Select a customer');
				return;
			}
			
			this.setState({
				showLoader: true,
				dataList: [],
				dataView: [],
			});
			
			axios.post(Api.Url+'v1/admin/machine/list/customer_wise_monthly_inputdata', $("#frm_MAchine_Mod").serialize()).then(res=>{
				
				if(res['data'].status=='1'){
					var s = groupByMultiple(res['data'].data, function(obj){
						return[obj.machine_id];
					});
					this.setState({
						dataList: res['data'].data,
						dataView: s,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			console.log(error);
			this.setState({showLoader: false});
		}
	}
	
	handleCustomerChange(value){
		this.setState({
			selectedCustomer: value
		});
	}
	
	exportTableToExcel(tableID, filename = ''){
		try{
			
			this.setState({
				showLoader: true
			});
				
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
			
			// Specify file name
			filename = filename?filename+'.xls':'excel_data.xls';
			
			// Create download link element
			downloadLink = document.createElement("a");
			
			document.body.appendChild(downloadLink);
			
			if(navigator.msSaveOrOpenBlob){
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob( blob, filename);
				
				this.setState({ showLoader: false });
			}else{
				// Create a link to the file
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
			
				// Setting the file name
				downloadLink.download = filename;
				
				//triggering the function
				downloadLink.click();
				
				this.setState({ showLoader: false });
			}	
			
		}
		catch(error){
			this.setState({ showLoader: false });
		}
	}
	
	loadData(items){
		var tr = [];
		{items.map((item,i)=>{
												
			tr.push( <tr key={i} >
				<td style={styles.innerTableHeaderTd}  >{i+1}</td>
				<td style={styles.innerTableHeaderTd} >{item.machine_code}</td>
				<td style={styles.innerTableHeaderTd} >{item.cumulative}</td>
				<td style={styles.innerTableHeaderTd} >{item.op_hrs}</td>
				<td style={styles.innerTableHeaderTd} >{item.stop_hours}</td>
				<td style={styles.innerTableHeaderTd} >{item.g1_gen}</td>
				<td style={styles.innerTableHeaderTd} >{item.g1_hrs}</td>
				<td style={styles.innerTableHeaderTd} >{item.g2_gen}</td>
				<td style={styles.innerTableHeaderTd} >{item.g2_hrs}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kwh}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kwh}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kuvarh_lag}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kvarh_lead}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kvarh}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kuarh_lag}</td>
				<td style={styles.innerTableHeaderTd} >{item.imp_kvarh_lead}</td>
				<td style={styles.innerTableHeaderTd} >{item.exp_kvarh}</td>
			</tr>);
		})
		}
		return tr;
	}
	
	getSumByField(items, field){
		try{
			
			var t = 0;
			items.map((item,i)=>{
				console.log(item[field]);
				t = parseFloat(t) + parseFloat(item[field]);
			});
			
			return parseFloat(t).toFixed(0);
		}
		catch(error){ 
			console.log(error);
		}
	}
	
	sumOfKvarh(items){
		try{
			
			var exp_kwh = 0;
			var imp_kvarh = 0;
			items.map((item,i)=>{
				
				exp_kwh = parseFloat(exp_kwh) + parseFloat(item.exp_kwh);
				imp_kvarh = parseFloat(imp_kvarh) + parseFloat(item.imp_kvarh);
			});
			
			return parseFloat((parseFloat(exp_kwh)/parseFloat(imp_kvarh)) * 100).toFixed(2);
		}
		catch(error){ 
			console.log(error);
		}
	}
	
	getListField(field){
		try{
			var d = this.state.dataList;
			return d[0][field];
		}
		catch(error){ }
	}
	
	handleServerExcelDownload(){
		try{
			this.setState({showLoader: true});
			axios.post(Api.Url+'v1/admin/machine/download/customer_monthly_data', $("#frm_MAchine_Mod").serialize()).then(res=>{
				
				if(res['data'].status=='1'){
					
					/* this.setState({
						dataList: res['data'].data,
						dataView: s,
						showLoader: false,
					}); */
					this.setState({
						showLoader: false,
					});
					window.open(Api.Url+res['data'].data,'_blank');
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			
		}
	}
	
	render(){
		
		let sno=1;
			
		return(
			<React.Fragment>
			
			<Menu />

				<Spin spinning={this.state.showLoader} >
				<Card>
					<Card.Body>
						<form
							action=""
							method="post"
							encType="multipart/form-data"
							id="frm_MAchine_Mod"
							onSubmit={this.handleFormSubmit} 
						>
						
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__customer" value={this.state.selectedCustomer} />
							
							<Row>
								<Col md={6} >
										<Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a customer"
										  optionFilterProp="children"
										  onChange={this.handleCustomerChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) =>
											  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											value={this.state.selectedCustomer}	
										>
										{this.state.customers.map((item,i)=>{
											return <Option value={item.customer_id} >{item.customer_name}</Option>;
										})
										}
									</Select>
								 </Col>
								 
								 <Col md={2} >
										<Form.Control
											id="i__fromDate"
											name="i__fromDate"
											siz="sm"
											type="date"
											max={momentDate((new Date()), 'YYYY-MM-DD')}
											required
										/>																		 
								 </Col>
								
								<Col md={2} >
									<Form.Control
										id="i__toDate"
										name="i__toDate"
										siz="sm"
										type="date"
										max={momentDate((new Date()), 'YYYY-MM-DD')}
										required
									/>																		 
								</Col>
								 
								 <Col md={2} >
									<div className="text-left" >
										<ButtonGroup >
											<Button type="submit" size="sm" variant="secondary" >
												Load Data
											</Button>
											{/*<Button size="sm" variant="outline-secondary" onClick={this.handleAddCustomerClick} >
												<i className="icofont-plus" /> Add Machine
											</Button>*/}
										</ButtonGroup>
									</div>
								 </Col>
							</Row>
							</form>
					</Card.Body>
				</Card>
				
				<Card>
						<Card.Body>
						{Object.keys(this.state.dataView).length>0 ? <Row className="mb-20" >
								<Col md={12}>
									<div className="text-right" >
										<Button
											type="button"
											size="sm"
											onClick={this.handleServerExcelDownload} 
										>	
											Export Excel
										</Button>
									</div>
								</Col>
						</Row> : null}
							
						{this.state.dataView.map((reading,m)=>{
																
							return <table  align="center"  style={{borderCollapse:'collapse', marginBottom: '20px', border: '2px solid black'}} id="tbl_export" width="100%" >
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="20"  style={styles.innerTableHeaderTd} >
										<h4>{upperCase(getls('loginCompany', 'master_company_name'))}</h4>
									</td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="10"  style={styles.innerTableHeaderTd} align="left"  >
										<div style={{textAlign: 'left'}} >
											Customer : {upperCase(this.getListField('customer_name'))}
										</div>
									</td>
									<td colSpan="10"  style={styles.innerTableHeaderTd} align="right" >
										<div style={{textAlign: 'right'}} >
											MF Value : {upperCase(this.getListField('machine_mf_value'))}
										</div>
									</td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="2" style={styles.innerTableHeaderTd}></td>
									<td style={styles.innerTableHeaderTd} >LCS</td>
									<td colSpan="7" style={styles.innerTableHeaderTd}></td>
									<td style={styles.innerTableHeaderTd} >MACHINE</td>
									<td colSpan="" style={styles.innerTableHeaderTd}></td>
									<td colSpan="3"  style={styles.innerTableHeaderTd} >TNEB GRID</td>
									<td colSpan=""  style={styles.innerTableHeaderTd} >TNEB METER</td>
									<td colSpan="4" style={styles.innerTableHeaderTd}></td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} >
									<td colSpan="2" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="3" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily<br />Gen</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily<br />Gen</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Opr<br />Hrs</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Gen<br />Hrs</td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="2" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="" style={styles.innerTableHeaderTd}></td>
									<td colSpan="" style={styles.innerTableHeaderTd}>Daily</td>
									<td colSpan="4" style={styles.innerTableHeaderTd}></td>
								</tr>
								<tr  style={{borderCollapse:'collapse'}} >
									<td style={styles.innerTableHeaderTd} >Date</td>
									<td style={styles.innerTableHeaderTd} >Code</td>
									<td style={styles.innerTableHeaderTd} >G1<br />Gen</td>
									<td style={styles.innerTableHeaderTd} >G1<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >G2<br />Gen</td>
									<td style={styles.innerTableHeaderTd} >G2<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Daily<br />Gen</td>
									<td style={styles.innerTableHeaderTd} >Daily<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Opr<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Gen<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Fr<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Mt<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Bd<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >Gd<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >AB<br />Hrs</td>
									<td style={styles.innerTableHeaderTd} >IMP<br />KWH</td>
									<td style={styles.innerTableHeaderTd} >EXP<br />KWH</td>
									<td style={styles.innerTableHeaderTd} >IMP<br />Kvarh</td>
									<td style={styles.innerTableHeaderTd} >% of Kvarh</td>
									<td style={styles.innerTableHeaderTd} >Notes</td>
								</tr>
								{reading.map((item,i)=>{
									
									return <tr key={i} >
										<td style={styles.innerTableHeaderTd}  >{momentDate(item.reading_on, 'DD-MMM-YYYY')}</td>
										<td style={styles.innerTableHeaderTd} >{item.machine_code}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.g1_gen).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.g1_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.g2_gen).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.g2_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.daily_gen).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd}  >{parseFloat(item.daily_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.opr_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.gen_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.fr_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.mt_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.bd_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.gd_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.ab_hrs).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.imp_kwh).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.exp_kwh).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.imp_kvarh).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{parseFloat(item.percentage_kvarh).toFixed(0)}</td>
										<td style={styles.innerTableHeaderTd} >{item.notes}</td>
									</tr>;
								})
								}
								
								<tr>
									<td style={styles.innerTableHeaderTd} >Total</td>
									<td style={styles.innerTableHeaderTd} ></td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'g1_gen')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'g1_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'g2_gen')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'g2_hrs')}</td>
									<td  style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'daily_gen')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'daily_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'opr_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'gen_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'fr_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'mt_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'bd_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'gd_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'ab_hrs')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'imp_kwh')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'exp_kwh')}</td>
									<td style={styles.innerTableHeaderTd} >{this.getSumByField(reading, 'imp_kvarh')}</td>
										{/*<td style={styles.innerTableHeaderTd} >{this.sumOfKvarh(reading)}</td>*/}
									<td style={styles.innerTableHeaderTd} >{((this.getSumByField(reading, 'imp_kvarh') / this.getSumByField(reading, 'exp_kwh')) * 100).toFixed(2)}</td>
									<td style={styles.innerTableHeaderTd} ></td>
										
								</tr>
							</table>
						})
						}
						</Card.Body>
					</Card>
					
				</Spin>
				
				
				
			</React.Fragment>
		);
	}
	
}
export default MachineMonthlyOutput;
