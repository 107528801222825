import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, integerIndMobile,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class AddMachineGroup extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save Group',
			customers: [],
			selectedCustomer: '',
			machines: [],
			machineByCustomer: [],
			selectedMachines: [],
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCustomerChange = this.handleCustomerChange.bind(this);
	}
	
	componentDidMount(){
		
		this.loadCustomers();
	}
	
	loadCustomers(){
		try{
			this.setState({
				showLoader: true,
				customers: [],
			});
			
			axios.get(Api.Url+'v1/admin/customers?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						customers: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
			
			axios.get(Api.Url+'v1/admin/machines?api='+this.context.state.api).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						machines: res['data'].data,
						showLoader: false,
					});
				}
				else{
					this.setState({showLoader: false});
				}
			});
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/machine/group/new', $("#frmAddMachineGroup_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddMachineGroup_Mod").reset();
					message.success(res['data'].message || 'Success');
					this.setState({
						selectedCustomer: '',
						showLoader: false
					});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	handleCustomerChange(value){
		try{
			var mch = this.state.machines.filter(function(obj){
				return(obj.customer_id==value);
			});
			this.setState({
				selectedCustomer: value,
				machineByCustomer: mch,
			});
		}
		catch(error){ }
	}
	
	getMachineIn(mid){
		try{
			var index = this.state.selectedMachines.findIndex(function(obj){
				return(obj.machine_id==mid);
			});
			
			return (index>-1);
		}
		catch(error){ return false;}
	}
	
	handleCheckboxChange(item){
		try{
			var d = this.state.selectedMachines;
			var index = d.findIndex(function(obj){
				return(obj.machine_id==item.machine_id);
			});
			
			if(index>-1){
				d.splice(index,1);
			}
			else{
				d.push(item);
			}
			
			this.setState({selectedMachines: d});
		}
		catch(error){ return false;}
	}
	
	render(){
		
						
		return(
			<React.Fragment>		
						
					<Spin spinning={this.state.showLoader} >
									
						<form 
							action=""
							method="post"
							encType="multipart/form-data"
							id="frmAddMachineGroup_Mod"
							onSubmit={this.handleFormSubmit} 
						>
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__customerId" value={this.state.selectedCustomer} />
							<input type="hidden" name="i__machines" value={JSON.stringify(this.state.selectedMachines)} />
							
										
							<Row className="mt-15" >
								<Col md={3} >
									<label className="font-14" > 
										Customer
										<span className="text-danger"> * </span>
									</label>
								</Col>
								<Col md={9} >
									<Select
										  showSearch
										  style={{ width: 180 }}
										  placeholder="Select a customer"
										  optionFilterProp="children"
										  onChange={this.handleCustomerChange}
										  style={{width: '100%'}}
										  filterOption={(input, option) =>
											  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
											}
											value={this.state.selectedCustomer}	
										>
										{this.state.customers.map((item,i)=>{
											return <Option value={item.customer_id} >{item.customer_name}</Option>;
										})
										}
									</Select>											
								</Col>
							</Row>
							
															
							<Row className="mt-15" >
								
								<Col md={12}  className="mt-15" >
									<table className="table table-sm table-bordered" >
										<thead>
											<tr>
												<th>Machine Code</th>
												<th>MF Value</th>
												<th>Location</th>
												<th>Capacity</th>
												<th>Manage</th>
											</tr>
										</thead>
										<tbody>
										{this.state.machineByCustomer.map((item,i)=>{
											return <tr key={i} >
												<td>{item.machine_code}</td>
												<td>{item.machine_mf_value}</td>
												<td>{item.machine_village}</td>
												<td>{item.machine_capacity}</td>
												<td align="center" onClick={this.handleCheckboxChange.bind(this,item)} >
													<input type="checkbox" checked={this.getMachineIn(item.machine_id)} />
												</td>
											</tr>;
										})
										}
										</tbody>
									</table>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								
								<Col md={12} >
									<div className="text-right" >
										
										<Button
											type="reset"
											size="sm"
											variant="danger"
										>
											<i className="icofont-close" ></i> Clear Form
										</Button>
										&emsp;
										<Button
											type="submit"
											size="sm"
											variant="success"
										>
											<i className="icofont-check" ></i> {this.state.btnText}
										</Button>
									</div>
								</Col>
							</Row>
						
						</form>
						
					
					</Spin>					
				
			</React.Fragment>
		);
	}
	
}
export default AddMachineGroup;
