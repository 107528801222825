import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, InputGroup, Collapse,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, upperCase, momentDate, integerIndMobile,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class UserPassword extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Update User',
			passwordText: '*****',
			showPasswordChange: false,
		}		
		
		this.getUserPassword = this.getUserPassword.bind(this);
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCollapseOpen = this.handleCollapseOpen.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/user/update_password', $("#frmEditAppUserPass_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({
						showLoader: false,
						showPasswordChange: false,
					});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getData(field){
		try{
			var d = this.props.data;
			return d[field];
		}
		catch(error){ }
	}
	
	getUserPassword(){
		try{
			
			this.setState({
				passwordText: '*****',
				showLoader: true,
			});
			var form = new FormData();
			form.append('api', this.context.state.api);
			form.append('i__id', this.getData('user_id'));
			axios.post(Api.Url+'v1/admin/user/password', form).then(res=>{
				if(res['data'].status=='1'){
					this.setState({
						passwordText: res['data'].data,
						showLoader: false,
					});
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({
						passwordText: '*****',
						showLoader: false,
					});
				}
			});			
		}
		catch(error){
			console.log(error);
			this.setState({
				passwordText: '*****',
				showLoader: false,
			});
		}
	}
	
	handleCollapseOpen(){
		this.setState({
			showPasswordChange: !this.state.showPasswordChange
		});
	}
	
	render(){
		
		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 16);
		
		return(
			<React.Fragment>		
						
					<Spin spinning={this.state.showLoader} >
									
						<form 
							action=""
							method="post"
							encType="multipart/form-data"
							id="frmEditAppUserPass_Mod"
							onSubmit={this.handleFormSubmit} 
						>
							<input type="hidden" name="api" value={this.context.state.api} />
							<input type="hidden" name="i__id" value={this.getData('user_id')} />
							
							<Row>
								<Col md={5} >
									<label className="font-14" > 
										Name of the User
										<span className="text-danger">  </span>
									</label>
								</Col>
								<Col md={7} >
									<Form.Control
										type="text"
										name="i__userName"
										size="sm"
										autoComplete={false}
										defaultValue={this.getData('user_name')}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-5" >
								<Col md={5} >
									<label className="font-14" > 
										Mobile Number
										<span className="text-danger">  </span>
									</label>
								</Col>
								<Col md={7} >
									<Form.Control
										type="number"
										name="i__userMobile"
										size="sm"
										autoComplete="off"
										onKeyPress={integerIndMobile}
										defaultValue={this.getData('user_mobile')}
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-10" >
								<Col md={5} >
									<label className="font-14" > 
										Current Password
										<span className="text-danger">  </span>
									</label>
								</Col>
								<Col md={7} >
									<InputGroup>
										<Form.Control
											type="text"
											name="i__currentPassword"
											size="sm"
											autoComplete="off"
											value={this.state.passwordText}
											required
										/>
										<InputGroup.Append style={{cursor: 'pointer'}} onClick={this.getUserPassword} >
											<InputGroup.Text>
												<i className="icofont-eye"></i>
											</InputGroup.Text>
										</InputGroup.Append>
									</InputGroup>
								</Col>
							</Row>
							
							
							<Row className="mb-15" >
								<Col md={12} onClick={this.handleCollapseOpen} style={{cursor: 'pointer'}} >
									<hr className="mt-20 mb-5" />
									<label>Change Password
										<span style={{
											position: 'absolute',
											right: '31px',
											fontSize: '22px',
										}} >
										{this.state.showPasswordChange ? 
											<i className="icofont-simple-up" ></i> : 
											<i className="icofont-simple-down" ></i>
										}
										</span>
									</label>
									<hr className="mt-5" />
								</Col>
								<Col md={12} >
									<Collapse in={this.state.showPasswordChange} >
										<div id="example-collapse-text">
											<Row className="mt-5" >
												<Col md={5} >
													<label className="font-14" > 
														New Password
														<span className="text-danger">  </span>
													</label>
												</Col>
												<Col md={7} >
													<Form.Control
														type="text"
														name="i__newPass"
														size="sm"
														autoComplete="off"
														required
													/>
												</Col>
											</Row>
											<Row className="mt-10" >
												<Col md={5} >
													<label className="font-14" > 
														Confirm Password
														<span className="text-danger">  </span>
													</label>
												</Col>
												<Col md={7} >
													<Form.Control
														type="text"
														name="i__confirmPass"
														size="sm"
														autoComplete="off"
														required
													/>
												</Col>
											</Row>
											<Row className="mt-10" >
												<Col md={12} >
													<div className="text-right" >
														<Button
															size="sm"
															variant="success"
															type="submit"
														>
															<i className="icofont-check"></i> Update Password
														</Button>
													</div>
												</Col>
											</Row>
										</div>
									</Collapse >
									
								</Col>
							</Row>
						
						</form>
						
					
					</Spin>					
				
			</React.Fragment>
		);
	}
	
}
export default UserPassword;
