import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import { getDateByFormat } from '../../../utils/appUtils';
import {
  getls, upperCase, momentDate, integerIndMobile,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class AddUser extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Save User',
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/user/new', $("#frmAddAppUser_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					document.getElementById("frmAddAppUser_Mod").reset();
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	
	render(){
		
		const date = new Date();
		const ageLimit = date.setFullYear(date.getFullYear() - 16);
		
		return(
			<React.Fragment>		
						
					<Spin spinning={this.state.showLoader} >
									
						<form 
							action=""
							method="post"
							encType="multipart/form-data"
							id="frmAddAppUser_Mod"
							onSubmit={this.handleFormSubmit} 
						>
							<input type="hidden" name="api" value={this.context.state.api} />
							<Row>
								<Col md={3} >
									<label className="font-14" > 
										Name of the User
										<span className="text-danger"> * </span>
									</label>
								</Col>
								<Col md={9} >
									<Form.Control
										type="text"
										name="i__userName"
										size="sm"
										autoComplete={false}
										
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={3} >
									<label className="font-14" > 
										Mobile Number
										<span className="text-danger"> * </span>
									</label>
								</Col>
								<Col md={9} >
									<Form.Control
										type="number"
										name="i__userMobile"
										size="sm"
										autoComplete="off"
										onKeyPress={integerIndMobile}
										required
									/>
									<span className="font-13 text-danger" >
										This number is used to login in mobile app
									</span>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={3} >
									<label className="font-14" > 
										Password
										<span className="text-danger"> * </span>
									</label>
								</Col>
								<Col md={9} >
									<Form.Control
										type="text"
										name="i__password"
										size="sm"
										autoComplete="off"
										required
									/>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={3} >
									<label className="font-14" > 
										DOB / Designation
										<span className="text-danger"> * </span>
									</label>
								</Col>
								<Col md={4} >
									<Form.Control
										type="date"
										name="i__dob"
										size="sm"
										autoComplete={false}
										max={getDateByFormat(ageLimit, 'YYYY-MM-DD')}
										required
									/>
								</Col>
								<Col md={5} >
									<Form.Control
										type="text"
										name="i__designation"
										size="sm"
										autoComplete={false}
										placeholder="User Designation"
										required
									/>
								</Col>								
							</Row>
							
							<Row className="mt-15" >
								<Col md={3} >
									<label className="font-14" > 
										User Address 
										<span className="text-danger"> * </span>
									</label>
								</Col>
								<Col md={9} >
									<Form.Control
										as="textarea"
										name="i__userAddress"
										size="sm"
										rows="5"
										autoComplete={false}
										required
									/>
								</Col>
							</Row>
														
							<Row className="mt-15" >
								<Col md={3} >
									<label className="font-14" > 
										Email Id
										<span className="text-danger">  </span>
									</label>
								</Col>
								<Col md={9} >
									<Form.Control
										type="email"
										name="i__userEmail"
										size="sm"
										autoComplete={false}
										
									/>
								</Col>
							</Row>
							
							<Row className="mt-15" >
								<Col md={12} >
									<div className="text-right" >
										
										<Button
											type="reset"
											size="md"
											variant="danger"
										>
											<i className="icofont-close" ></i> Clear Form
										</Button>
										&emsp;
										<Button
											type="submit"
											size="md"
											variant="success"
										>
											<i className="icofont-check" ></i> {this.state.btnText}
										</Button>
									</div>
								</Col>
							</Row>
						
						</form>
						
					
					</Spin>					
				
			</React.Fragment>
		);
	}
	
}
export default AddUser;
