import React, { Component } from 'react';
import {NavLink} from 'react-router-dom';
import $ from 'jquery';


class Menu extends Component{
	
	
	constructor(props){
		super(props);
		this.state = {
			
		}		
				
	}
	
	
	
	render(){
			
		return(
			<React.Fragment>
			
				<ul className="nav nav-tabs">
				  <li className="nav-item">
					<NavLink className="nav-link" to="/app/machine-excep-report">Daily Output</NavLink>
				  </li>
				  <li className="nav-item">
					<NavLink className="nav-link" to="/app/machine-input-report">Daily Input</NavLink>
				  </li>
				   <li className="nav-item">
					<NavLink className="nav-link" to="/app/machine-monthly-output">Monthly Output</NavLink>
				  </li>
				</ul>
			
			</React.Fragment>
		);
	}
	
}
export default Menu;
