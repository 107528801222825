/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import Notifications from 'react-notify-toast';
import routes from '../views';
import { Header, SideNav } from '../views/elements';
import nav from '../_nav';

class SiteLayout extends Component {
  componentDidMount() {

  }

  render() {
    return (

      <div className="ps-admin">

        <Notifications />

        <SideNav nav={nav} />

        <div className="main">

          {/* <SideNav nav={nav} /> */}

          <Header
            routes={routes}
            {...this.props}
          />

          <div className="main-body">

            <Switch>
              {routes.map((page) => (
                <Route exact path={page.path} component={page.component} key={page} />
              ))}
            </Switch>

          </div>

        </div>
		
		
		<iframe name="print_frame" id="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>

      </div>

    );
  }
}
export default SiteLayout;
