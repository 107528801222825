import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab, Table, 
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate, integerIndMobile,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

const { Option } = Select;

class ViewData extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
			btnText: 'Update Machine',
			customers: [],
			selectedCustomer: [],
		}		
		
		this.handleFormSubmit = this.handleFormSubmit.bind(this);
		this.handleCustomerChange = this.handleCustomerChange.bind(this);
	}
	
	componentDidMount(){
		

	}
	
	
	handleCustomerChange(value){
		this.setState({
			selectedCustomer: value
		});
	}
	
	handleFormSubmit(e){
		e.preventDefault();
		try{
			this.setState({showLoader: true});
			
			axios.post(Api.Url+'v1/admin/machine/update', $("#frmEditMachine_Mod").serialize()).then(res=>{
				if(res['data'].status=='1'){
					message.success(res['data'].message || 'Success');
					this.setState({showLoader: false});
					
					if(this.props.afterFinish)
						this.props.afterFinish();
				}
				else{
					message.error(res['data'].message || 'Error');
					this.setState({showLoader: false});
				}
			});
			
		}
		catch(error){
			this.setState({showLoader: false});
		}
	}
	
	getData(field){
		try{
			var d = this.props.data;
			return d[field];
		}
		catch(error){ }
	}
	
	render(){
		
						
		return(
			<React.Fragment>		
							
			<Table>
				<tbody>
					<tr>
						<td colSpan="2" align="center" >{upperCase(getls('loginCompany', 'master_company_name'))}</td>
					</tr>
					<tr>
						<td style={{fontSize: '14px'}} >
							Customer : {this.getData('customer_name')}<br />
							Email : {this.getData('customer_email')}<br />
							Reading On : {this.getData('reading_on')} {this.getData('reading_time')}
						</td>
						<td style={{fontSize: '14px'}} >
							Machine Code : {this.getData('machine_code')}<br />
							Location: {this.getData('machie_site_location')}<br />
							MF Value : {this.getData('machine_mf_value')}
						</td>
					</tr>
					<tr>
						<td colSpan="">
							<table width="100%" >
								<tr> <td>cumulative</td> <td align="right" >{this.getData('cumulative')}</td> </tr>
								<tr> <td>op_hrs</td> <td align="right" >{this.getData('op_hrs')}</td> </tr>
								<tr> <td>stop_hours</td> <td align="right" >{this.getData('stop_hours')}</td> </tr>
								<tr> <td>g1_gen</td> <td align="right" >{this.getData('g1_gen')}</td> </tr>
								<tr> <td>g1_hrs</td> <td align="right" >{this.getData('g1_hrs')}</td> </tr>
								<tr> <td>g2_gen</td> <td align="right" >{this.getData('g2_gen')}</td> </tr>
								<tr> <td>g2_hrs</td> <td align="right" >{this.getData('g2_hrs')}</td> </tr>
								<tr> <td>imp_kwh</td> <td align="right" >{this.getData('imp_kwh')}</td> </tr>
								<tr> <td>c1</td> <td align="right" >{this.getData('c1')}</td> </tr>
								<tr> <td>c2</td> <td align="right" >{this.getData('c2')}</td> </tr>
								<tr> <td>c3</td> <td align="right" >{this.getData('c3')}</td> </tr>
								<tr> <td>c4</td> <td align="right" >{this.getData('c4')}</td> </tr>
								<tr> <td>c5</td> <td align="right" >{this.getData('c5')}</td> </tr>
							</table>
						</td>
						<td colSpan="">
							<table width="100%" >
								<tr> <td>exp_kwh</td> <td align="right" >{this.getData('exp_kwh')}</td> </tr>
								<tr> <td>exp_c1</td> <td align="right" >{this.getData('exp_c1')}</td> </tr>
								<tr> <td>exp_c2</td> <td align="right" >{this.getData('exp_c2')}</td> </tr>
								<tr> <td>exp_c3</td> <td align="right" >{this.getData('exp_c3')}</td> </tr>
								<tr> <td>exp_c4</td> <td align="right" >{this.getData('exp_c4')}</td> </tr>
								<tr> <td>exp_c5</td> <td align="right" >{this.getData('exp_c5')}</td> </tr>
								<tr> <td>imp_kuvarh_lag</td> <td align="right" >{this.getData('imp_kuvarh_lag')}</td> </tr>
								<tr> <td>exp_kvarh_lead</td> <td align="right" >{this.getData('exp_kvarh_lead')}</td> </tr>
								<tr> <td>exp_kuarh_lag</td> <td align="right" >{this.getData('exp_kuarh_lag')}</td> </tr>
								<tr> <td>imp_kvarh_lead</td> <td align="right" >{this.getData('imp_kvarh_lead')}</td> </tr>
								<tr> <td>imp_kvarh</td> <td align="right" >{this.getData('imp_kvarh')}</td> </tr>
								<tr> <td>exp_kvarh</td> <td align="right"  >{this.getData('exp_kvarh')}</td> </tr>
								<tr> <td colSpan="2"></td> </tr>
							</table>
						</td>
					</tr>
					<tr> <td colSpan="2">
						Notes : {this.getData('notes')}
					</td> </tr>
					<tr> <td colSpan="2"></td> </tr>
				</tbody>
			</Table>
			
			</React.Fragment>
		);
	}
	
}
export default ViewData;
