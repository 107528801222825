import React, { Component } from 'react';
import $ from 'jquery';
import {
  Row, Col, Card, Button, Form, Spinner, ButtonGroup, Modal, Tabs, Tab,
} from 'react-bootstrap';
import iziToast from 'izitoast';
import axios from 'axios';
import {
  Table, Drawer, Skeleton, Spin, message, Tooltip, Avatar, Image, Select, Switch 
} from 'antd';

import { Api } from '../../../utils/Variables';
import {
  getls, upperCase, momentDate,
} from '../../../utils';
import { SpinLoader } from '../../elements';
import PsContext from '../../context/PsContext';

import BasicSettings from './BasicSettings';

const { Option } = Select;

class Settings extends Component{
	
	static contextType = PsContext;
	
	constructor(props){
		super(props);
		this.state = {
			showLoader: false,
		}		
	}
	
	
	render(){
		
						
		return(
			<React.Fragment>
				
				<Row className="tab-page" >
					<Col md={{span:8, offset: 2}} >
							
						<Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
						  <Tab eventKey="home" title="Baisc Settings">
								<BasicSettings />
						  </Tab>
						  {/*<Tab eventKey="profile" title="SMS Settings">
							
						  </Tab>
						  <Tab eventKey="contact" title="Contact" >
							
						  </Tab>*/}
						</Tabs>
					</Col>
				</Row>
				
			</React.Fragment>
		);
	}
	
}
export default Settings;
