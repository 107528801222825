/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import Customers from './pages/Customers';
import Users from './pages/Users';
import Machines from './pages/Machines';
import MachineGroup from './pages/Machines/MachineGroup';
import MachineAllocation from './pages/MachineAllocation';
import MachineReport from './pages/MachineReport';
import MachineExcelReport from './pages/MachineExcelReport';
import MachineDailyInput from './pages/MachineExcelReport/MachineDailyInput';
import MachineMonthlyOutput from './pages/MachineExcelReport/MachineMonthlyOutput';

const pageList = [
  { name: 'Dashboard', path: '/app', component: Dashboard },
  { name: 'Settings', path: '/app/settings', component: Settings },
  { name: 'Customers', path: '/app/customers', component: Customers },
  { name: 'Users', path: '/app/app-users', component: Users },
  { name: 'Machines', path: '/app/machines', component: Machines },
  { name: 'Machine Group', path: '/app/machines/group', component: MachineGroup },
  { name: 'Machine Allocation', path: '/app/machine-allocation', component: MachineAllocation },
  { name: 'Machines Report', path: '/app/machine-daily-report', component: MachineReport },
  { name: 'Daily Output', path: '/app/machine-excep-report', component: MachineExcelReport },
  { name: 'Daily Input', path: '/app/machine-input-report', component: MachineDailyInput },
  { name: 'Monthly Output', path: '/app/machine-monthly-output', component: MachineMonthlyOutput },
 
];

export default pageList;
